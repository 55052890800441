import React, { useState } from "react";
import "./edneed.scss";
import EdneedImage from "./edneed.png";
import FeatureImage1 from "../../../../Assets/images/feature1.png";
import FeatureImage2 from "../../../../Assets/images/featue2.png";
import FeatureImage3 from "../../../../Assets/images/feature3.png";
import c1 from "./edneed-client-1.png";
import c2 from "./edneed-client-2.png";
import c3 from "./edneed-client-3.png";
import c4 from "./edneed-client-4.png";
import c5 from "./edneed-client-5.png";
import c6 from "./edneed-client-6.png";
import FormPopup from "../../../Home/FormPopup/FormPopup";
const Edneed = () => {
  const [openContactPopup, setOpenContactPopup] = useState(false);

  const openContactpopup = () => {
    setOpenContactPopup(!openContactPopup);
  };
  const closeContactpopup = () => {
    setOpenContactPopup(false);
  };
  return (
    <React.Fragment>
    <div className="innovation-wrap">
    <div className="container">
        <div className="invoation-leftSidebar">
          <h3 className="invoation-heading">
          Reinventing the landscape <br/>of online education
          </h3>
          <p className="invoation-para">
          The Brandneed is the ultimate destination for all the requirements that your Brand needs. We, at Brandneed, offer the best marketing solutions that can instantly boost your brand’s reputation and skyrocket its growth. The Brandneed can uplift your brand’s reputation in no time.

          </p>
          <div className="edneed-btn-wrap">
            <a href="https://edneed.com/" rel="noreferrer" target={"_blank"} className="button btn-oval btn-o-primary ">
              VISIT NOW
            </a>
          </div>
        </div>
        <div className="invoation-rightSidebar">
          <img src={EdneedImage} alt="" />
        </div>
      </div>
      </div>
    <div className="container edneed-container">
      <div className="our-feature-wrap">
        <p className="feature-p">OUR FEATURES</p>
        <div className="feature-content-wrap">
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage1} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Learning Management<br/> System (LMS)
              </h4>
              <p className="feature-content-p">
              Make learning and teaching a seamless procedure with Edneed learning management system. 
Manage classrooms effortlessly. Create, conduct, and evaluate class tests with ease. Prepare and share online study materials, Keep track of all the activities and get notifications about real-time information about the progress of students and teachers.

              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage2} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Enterprise Resource<br/> Planning (ERP)
              </h4>
              <p className="feature-content-p ">
              Simplify your school’s day-to-day tasks with Edneed enterprise resource planning. Shed the burden of keeping physical records and get real-time data information about all the employees.
Keep payment records in check with automated fee payments, get the benefit of centralized data management, and experience a faster and more efficient management process.
              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage3} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Digitalize <br/>your school
              </h4>
              <p className="feature-content-p ">
              Create an online presence for your school or institute with Edneed website builder tool. 
Get a dynamic and alluring website with a seamless user interface. Provide information about your school or institute, Notify people about admission updates, and processes, and Provide information about courses and fee structure through the website.

              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-container">
        <div className="partner-leftSidebar">
          <p className="brand-p">BRANDS</p>
          <h4 className="client-heading">
            Clients and <br />
            Frequent <br /> Partners
          </h4>
          <p className="client-subheading">
          Helping your organization to reach its potential, driving bold ideas and pragmatic solutions for optimum outcomes.          </p>
        </div>
        <div className="partner-rightSidebar">
          <div className="parner-images-container">
          <div className="image-div ">
              <img src={c1} alt="" />
            </div>
            <div className="image-div ">
              <img src={c2} alt="" />
            </div>
            <div className="image-div ">
              <img src={c3} alt="" />
            </div>
            <div className="image-div ">
              <img src={c4} alt="" />
            </div>
            <div className='image-div '>
              <img src={c5} alt="" />
            </div>
            <div className='image-div '>
              <img src={c6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-parnet-wrap">
        <button className="button btn-oval btn-o-primary" type="button" onClick={openContactpopup} >CONTACT US</button>
      </div>
    </div>
    {openContactPopup && (
                  <FormPopup closeContactpopup={closeContactpopup} />
                )}
    </React.Fragment>
  );
};

export default Edneed;
