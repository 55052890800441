import React, {useState} from 'react'
import  "./faq.scss";
import faqList from "./faq_data.json";

const Faq = () => {
    const [showAnwer, hideAnswer] = useState(-1);
  function showHideFaq(index) {
    hideAnswer(showAnwer === index ? -1 : index);
  }
  return (
    <div className="container">
       <div className="faq-wrapper">
       <div className="faq-head">
        <h2>FAQs</h2>
        <p>Have questions? We’re here to help</p>
        </div> 
        <div className="faq-content">
        {faqList.map((faqList, index) => {
              return (
                <article
                  key={index}
                  id={index}
                  className={showAnwer === index ? "active" : ""}
                >
                  <div className="faqHead" onClick={() => showHideFaq(index)}>
                    {faqList.question}
                    <i className={`caret ${showAnwer === index ? "active" : ""}`}></i>
                  </div>
                  {showAnwer === index ? <div
                    className="faqAnswerDisp"
                    dangerouslySetInnerHTML={{ __html: faqList.answer }}
                  ></div> : ""}
                </article>
              );
            })}
        </div>
        </div> 
    </div>
  )
}

export default Faq