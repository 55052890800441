import React from 'react';
import './pageNotFound.scss';
import pageNotFoundImg from './pageNotFoundImg.png';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const Navigate = useNavigate()
    return (
        <div className="container">
            <div className='PagaNotFound-main-container'>
                <div className="PagaNotFound-sub-container">
                    <div className="sub-img-container">
                        <img src={pageNotFoundImg} alt="Img" />
                    </div>
                    <div className="sub-content-container">
                        <h3 className='sub-content-heading'>Oops, Page Not  Found</h3>
                        <p className='sub-content-description'>
                            We searched high and low but couldn’t find what
                            you’re looking for
                        </p>
                        <button onClick={() => Navigate('/')} className='bottom-button'>Go home</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound