import React from "react";
import Form from "../../../Common/CommonJsx/Form/Form";
import "./contactSection.scss";
import { motion } from "framer-motion";

const ContactSection = () => {
  return (
    <div className='container'>
     <div
        className="contactsection-container"
      >
    <div className="contactSection-leftSidebar">
        <p className='contact-p'>CONTACT</p>
        <div className='contact-info-wrap'>
            <h4 className='contact-info-heading'>GET IN TOUCH</h4>
            <p>Got something in your mind? We are here to help.</p>
            <div className='address-wrap'>
                <div className='icon-wrap'>
                    <i className='icon-call'></i>
                    <p>+91-83682 14889</p>
                </div>
                <div className='icon-wrap'>
                    <i className='icon-mail'></i>
                    <p>support@b-need.com</p>
                </div>
                <div className='icon-wrap'>
                    <i className='icon-loaction'></i>
                    <p> <b>India : </b> - 203, Tower C, Ithum Towers, <br/> Sector
                         62, Noida, Uttar Pardesh 201309</p>
                </div>
                <div className='icon-wrap'>
                    <i className='icon-loaction'></i>
                    <p> <b>USA : </b> - 9009 North Plaza, Unit 141 Austin Texas 78753</p>
                </div>
            </div>
            </div>
          </div>
        <div className="contactSection-rightSidebar">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
