import React, {useState} from 'react'
import {NavLink} from 'react-router-dom';
import Logo from "../../Assets/images/logo.png";
import "./header.scss";

const Header = () => {
  const [openDrop, setOpenDrop] = useState(false);
  const[navChangeColor,setNavChangeColor] =useState(false);
  const handleOpen = () => {
    setOpenDrop(prev => !prev)
  }
 
  const handleChangeNavColor = ()=> {
    if(window.scrollY >= 200) {
      setNavChangeColor(true)
    }
    else {
      setNavChangeColor(false)
    }
  }

  window.addEventListener('scroll',handleChangeNavColor);

  return (
    <header className={`${navChangeColor? 'active' : ''}`}>
    <div className='container'>
    <nav className='bneed-header'>
       <a href="/"  className='logo-wrapper'> <img className='logo' src={Logo} alt="" /></a>
      <button className='hamburger-toggle' onClick={handleOpen} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className='hamburger-toggle-item'></span>
        <span className='hamburger-toggle-item'></span>
        <span className='hamburger-toggle-item'></span>
      </button>
      <div className={`nav-menu-wrapper ${openDrop ? "show" : ""}`}   >
        <ul className='menu-list-wrapper'>
        <li className={`menu-item`}><NavLink to={"/"} className='menu-item-anchor' end>Home</NavLink></li>
        <li className={`menu-item `}><NavLink  to={"/our-business"} className='menu-item-anchor'>Our Business</NavLink></li>
        <li className='menu-item'><NavLink   to="/about" className='menu-item-anchor'>About Us</NavLink></li>
        <li className='menu-item'><NavLink  to="/contact" className='menu-item-anchor' >Contact Us</NavLink></li>
        <li className='menu-item'><NavLink  to="/career" className='menu-item-anchor'>Career</NavLink></li>
      </ul>
      </div>
      </nav>
      </div>
    </header>
  )
}

export default Header