import React from "react";
import "./customerReviews.scss";
import Slider from "react-slick";
import Gokul from "./gokul.jpg";
import Dinesh from "./dinesh.png";
import Nav from "./nav.jpg";
import { motion } from "framer-motion";
import Star from "./star.svg";

const CustomerReviews = () => {
    const reviewData = [
        {
            id:1,
            name: 'Gokul Chand Saini',
            post:'Director',
            company:'Uva Jagriti Sansthan',
            image:Gokul,
            message: 'Exceptional services, the marketing team is exceptional and they know their work. I would definitely recommend the B-Need to others. I give 5 out of 5 to the services that the B-Need provides.',
            star: 4
        },
        {
            id:2,
            name: 'Dinesh Sood ',
            post:'Director',
            company:'Kasauli Exports Surplus',
            image:Dinesh,
            message: 'The B-Need’s services are proving to be a game changer for us. I have witnessed an increase in my business and profit since the day we joined hands with the B-Need. We are 100% content with the services. ',
            star: 5
        },
        {
            id:3,
            name: 'Nav Prabhat Singh',
            post:'Principal Architect',
            company:'The Lacuna Story',
            image:Nav,
            message: 'The B-Need has a professional team with good knowledge of the work. They not only provide services but also give suggestions for optimum outcomes. For us, their services deserve no less than 5 stars.',
            star: 4
        }
    ]
    const settings = {
      // dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: reviewData.length > 3 ? 3 : 3,
      slidesToScroll: 3,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="rivew-wrapper">
        <h1
          className="main-heading customer-heading"
        >
          Our Satisfied <br /> Customers
        </h1>
        <div
        >
        <Slider {...settings}>
            {
                reviewData.map((item, key)=> (
                  <div className='image-wrap' key={key}>
                  <img src={item.image} alt="" />
                  <div className='intro-section'>
                  <div className='intro-section-content'>
                      <h3 className='name'>{item.name}</h3>
                      <p className='designation'>{item.post}</p>
                      <p className='designation'>{item.company}</p>
                      <p className='details'>{item.message} </p>
                      </div>
                      <div className='starSection'>
                    {  [...Array(item.star)].map((e, i) => <img src={Star} alt="" key={i}/>)}
                        
                      </div>
                  </div>
               </div>
                     
                ))
            }
        </Slider>
        </div>
    </div>
    </div>
  );
};

export default CustomerReviews;
