import React from 'react'
import './aboutus.scss';
import AboutUsImage from '../../../Assets/images/aboutUs.png';
import Sammy from './sammy.png';
import Harpreet from './harpreet.png';
import Raj from './raj.png';
import Journail from './journail.png';
import Mahima from './mahima.png';
import ValueImage from './values-image.png'
const Aboutus = () => {
  const teamData = [
    {
      id:1,
      image:Sammy,
      name: 'Saranjeet Singh',
      position: 'CEO & FOUNDER'
    },
    {
      id:2,
      image:Raj,
      name: 'Raj Malhotra',
      position: 'PARTNER/BOARD OF DIRECTOR'
    },
    {
      id:3,
      image:Journail,
      name: 'Jarnail Singh',
      position: 'BOARD OF ADVISOR'
    },
    {
      id:4,
      image:Harpreet,
      name: 'Harpreet Kaur',
      position: 'IT HEAD'
    },
    {
      id:4,
      image:Mahima,
      name: 'Mahima Anand',
      position: 'DIRECTOR OF BUSINESS'
    },


  ]
  return (
    <div className='aboutUs-container'>
      <div className='container'>
         <h1 className='main-heading'>About us</h1>
      </div>
        <div className='aboutTopSection'>
      <div className='container aboutTopSectionGrid'>
          <div className='aboutTop-leftside'>
            <img src={AboutUsImage} alt="" />
          </div>
          <div className='aboutTop-rightside'>
            <p className='who-p'>WHO WE ARE</p>
            <h3 className='who-subheading'>We’re  a team of<br/> creators & innovators </h3>
            <p className='aboutus-para'>The B-NEED is the link between the technology of the present and the future. The B-NEED is the best from the world of education, marketing, and business solutions consolidated in digitized form in a single place. The aim of B-NEED is to fulfill the technological gap in various business verticals through our state-of-the-art products. We have the experience, the expertise, and the access to the required tech and we know how to harness all of this to deliver exceptional results, help businesses grow, and assist them in reaching their potential. We are the B-NEED, we are unmatched when it comes to fulfilling all your business needs.
</p>
          </div>
        </div>
      </div>
      <section className='ourValuesSection'>
        <div className='container'>
        <h1 className='main-heading'>Our Values</h1>
           <div className='value-container'>
           <div className='value-container-grid'>
            <div className='value-leftSidebar'>
              <div className='intri-wrap'>
                <h4 className='intri-heading'>Integrity</h4>
                <p className='intri-para'>Honesty is the best policy. At B-Need, we believe that one should observe honesty with their work, peers, 
                  society but most importantly themselves. Because it is us who are most affected by our actions.</p>
              </div>
              <div className='intri-wrap value-gap'>
                <h4 className='intri-heading'>Equality</h4>
                <p className='intri-para'>We believe that the greatest privilege in life is to have access to an exemplary mentor. B-Need is our 
                step towards making the world a level plain field.</p>
              </div>
              <div className='intri-wrap value-gap'>
                <h4 className='intri-heading'>Selfless service</h4>
                <p className='intri-para'>We are dedicated to giving back to our society. By making knowledge accessible, finding opportunities easier and empowering educational
                 institutes with tools that will take them to the next level.</p>
              </div>
            </div>
            <div className='value-rightSidebar'>
              <img src={ValueImage} alt="" />
            </div>
           </div>
           </div>
           </div>
      </section>
      <section className='teamSection'>
       <div className='container'>
        <h1 className='main-heading'>Meet our team</h1>
        <p className='teamHeading-para tex-center'>Our philosophy is simple  - hire a team of diverse, passionate people and foster a culture 
            that empowers you to do you best work </p>
   
            <div className='container'>
       <div className='team-images-section'>
        {
          teamData.map(({id,image,name,position})=>(
            <div className='team-wrap' key={id}>
              <div className='image-wrap'>
               <img src={image} alt="" />
               </div>
               <p className='text-center team-name'>{name}</p>
               <p className='text-center team-position'>{position}</p>
           </div>
          ))
        }
      
       </div>
       </div>
       </div>
      </section>
 
    </div>
  )
}

export default Aboutus