import React from 'react'
import './inputForm.scss'
const InputForm = ({value,label,Type,...otherProps}) => {
  return (
    <div className="group">
    <input className='form-input' type={Type} value={value} {...otherProps}/>
    {
    
    label ?
    (value?"":
      <label className={`${otherProps?.value?.length  ? 'shrinkLabel ':''}form-input-label`}>
        {label}
        </label> ): null
}

</div>
  )
}

export default InputForm