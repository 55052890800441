import logo from './logo.svg';
import './App.css';
import PhoneIcon from "./phone.svg";
import React, {useState, useEffect} from 'react';
import Layout from './Layout';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter } from "react-router-dom";
import AppRoutes from './routes/AppRoutes';

function App() {

  return (
    <BrowserRouter>
      <Layout>
      <AppRoutes/>
     </Layout>
   </BrowserRouter>

  );
}

export default App;
