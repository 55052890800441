import React from 'react'
import { NavLink } from 'react-router-dom'

const Privacy = () => {
  return (
    <div className="privacy-policy-custom">
    <div className="container">
    {/* <div className="logoCustom">
          <img alt="Edneed Brand Logo" className="brandLogo large" src={logo} />
        </div> */}
    <h1 className="sub-heading">Privacy Policy</h1>
    <p className="">
      By accepting this privacy policy, you (a) acknowledge that you have
      read and understand this agreement; (b) represent that you are of
      legal age and competent to enter into a binding agreement or have a
      parental consent and the parent/legal guardian have agreed to these
      terms; (c) will comply and be legally bound by its terms. If you do
      not agree to these terms, do not download/ install/use the software
      or services.
    </p>
    <p className="">
      If you are entering into this agreement on behalf of an educational
      institution or other legal entity, you represent that you have the
      authority to bind such entity to the terms and conditions of this
      agreement and, in such event, “you” and “your” as used in this
      agreement may refer to such entity. If you do not have such
      authority, or if you or such entity do not agree to the terms and
      conditions of this agreement, you may not use the software or
      services.
    </p>
    <p className="">
      This Privacy Policy (“Agreement”) is between{" "}
      <span className="">Edneed Technology Private Limited</span> (“
      <span className="">Edneed</span>”, “
      <span className="">Company</span>”) and You (“
      <span className="">You</span>”, “
      <span className="">Your</span>”).
    </p>
    <div className="olCounter">
      <ol>
        <li>
          <h3 className="text-xs primary">Definitions</h3>
          <p className="">
            "Software" means certain software applications each such
            application together with any applicable documentation
            thereto, and programming and user interfaces provided by the
            Company for use to You on a single desktop or mobile.
          </p>
          <p className="">
            "Subscription Term" shall mean that time period selected by
            You when subscribing to the Services under which You will have
            access and use of the Software and/or Services. The
            Subscription Term shall automatically renew for same
            successive initial term periods unless You deliver written
            notice of non-renewal to the Company at least 30 days prior to
            the expiration of the then-current Subscription Term.
          </p>
          <p className="">
            “Terms and Conditions” means the terms applicable to You when
            You access and/or use the Company website or Software, as
            amended from time to time available at &nbsp;
            <NavLink to="/" target="_blank">
              https://b-need.com/
            </NavLink>
            .
          </p>
          <p className="">
            “Privacy Policy” means the Company Privacy Policy describing
            the policies and procedures of the Company on the collection,
            use, disclosure and protection of users information when any
            user accesses or use the Company website or Software as
            amended from time to time available at{" "}
            <NavLink to="/" target="_blank">
              https://b-need.com/
            </NavLink>
            .
          </p>
          <p className="">
            “Your Data” means all non-public data provided by You to
            Company to enable the provision of access to, and use of, the
            Services such as software, text including any suggestion,
            feedback, recommendations, images, audio, video, photographs,
            and other content and material, in any format, provided,
            recorded and stored on the Software. “Company Intellectual
            Property” means each (i) Software and the Services and all
            improvements, enhancements, derivative works or modifications
            thereto, (ii) any software, applications, inventions or other
            technology developed in connection with the Software or
            Services, and (iii) all intellectual property and proprietary
            rights in and related to any of the foregoing.
          </p>
          <p className="">
            “Company Intellectual Property” means each (i) Software and
            the Services and all improvements, enhancements, derivative
            works or modifications thereto, (ii) any software,
            applications, inventions or other technology developed in
            connection with the Software or Services, and (iii) all
            intellectual property and proprietary rights in and related to
            any of the foregoing.
          </p>
          <p className="">
            “Third Party Data” means all software, data, text, images,
            audio, video, photographs and other content and material, in
            any format, that are obtained or derived from third party
            sources outside of Company that You may access through,
            within, or in conjunction with the use of Services.
          </p>
          <p className="">
            “Services” means the Software and applicable services provided
            to You as a part of Your subscription.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Software and Services
          </h3>
          <p className="">
            Company will make available the Software and applicable
            services to You. Subject to the terms and conditions of this
            Agreement, during the Term, Company shall use commercially
            reasonable efforts to provide You access to the Software and
            the Services.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            License to Access and Use
          </h3>
          <p className="">
            Except as otherwise stated in this Agreement, You will have
            non-exclusive, non-sublicensable, non transferable, worldwide,
            limited right only to download, access and use the Software
            and Services during the Subscription Term on a single mobile
            device and a single desktop device unless earlier terminated
            in accordance with this Agreement, solely for Your educational
            purpose as indicated in the subscription package.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Restrictions</h3>
          <p className="">
            You will not, nor permit or encourage any third party to,
            directly or indirectly:
          </p>
          <ol className=" nest-1">
            <li>
              reverse engineer, decompile, disassemble or otherwise
              attempt to discover or derive the source code, object code
              or underlying structure, ideas, know-how or algorithms
              relevant to the Software or reproduce, republish or copy any
              part of the Software or Services (including data structures
              or similar materials produced by programs);
            </li>
            <li>
              modify, translate, or create derivative works based on
              Services;
            </li>
            <li>
              modify, remove or obstruct any proprietary notices or
              labels;
            </li>
            <li>
              access or use the Services to build or support, directly or
              indirectly, products or services competitive to the Company;
            </li>
            <li>
              license, sell, transfer, assign, distribute, outsource,
              permit timesharing or service bureau use of, commercially
              exploit, or make available the Services to any third party.{" "}
            </li>
          </ol>
        </li>
        <li>
          <h3 className="text-xs primary">Fees</h3>
          <p className="">
            You will pay Company the then-applicable fees described in the
            subscription package availed by You. You agree that once the
            subscription package is ordered, it is non-cancelable and any
            fees paid are nonrefundable, except as provided in this
            Agreement. If You exceed the quantity of Services ordered, You
            shall be liable to purchase and pay fees for the additional
            quantity.
          </p>
          <p className="">
            Upon the commencement of each new Subscription Term, You shall
            be liable to Company for payment of a renewal fee.
          </p>
          <p className="">
            In addition to the Fees, if applicable, You shall reimburse
            Company for reasonable out-of-pocket expenses incurred by
            Company in connection with providing the Services. Unless
            otherwise provided in the subscription package, Company shall
            invoice You for all fees (plus any applicable taxes) as per
            the subscription package and You shall pay all invoices as per
            the period specified in the subscription package or if not
            specified within thirty (30) days of the invoice date.
          </p>
          <p className="">
            Unpaid amounts are subject to an interest charges of 1.5% per
            month on any outstanding balance, or the maximum permitted by
            law, plus all expenses of collection. In addition to any other
            remedies available, Company may suspend Services in the event
            of payment delinquency. All amounts payable to Company
            hereunder shall be paid by You in full without any setoff,
            recoupment, counterclaim, deduction, debit or withholding for
            any reason except as may be required by applicable law.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Your Responsibilities
          </h3>
          <ol className=" nest-1">
            <li>
              You shall use the Software only for personal educational
              purpose as per the subscription package availed.
            </li>
            <li>
              You shall at all times be in compliance with this Agreement.
            </li>
            <li>
              You shall not use the Services to harass any person; cause
              damage or injury to any person or property; publish any
              material that is false, defamatory, harassing or obscene;
              violate privacy rights; promote bigotry, racism, hatred or
              harm; send unsolicited bulk e-mail, junk mail, spam or chain
              letters; infringe property rights; or otherwise violate
              applicable laws, ordinances or regulations.
            </li>
            <li>
              You shall use all efforts to ensure that Services are used
              in accordance with applicable manuals, instructions,
              specifications and documentation provided by Company from
              time to time or available on the Company website or
              application.
            </li>
            <li>
              You shall be responsible for obtaining and maintaining—both
              the functionality and security of any equipment and
              ancillary services needed to connect to, access or otherwise
              use the Services, including modems, hardware, servers,
              software, operating systems, networking, web servers and the
              like.
            </li>
            <li>
              You are solely responsible for collecting, inputting and
              updating all Your Data, and for ensuring that Your Data does
              not (i) include anything that actually or potentially
              infringes or misappropriates the copyright, trade secret,
              trademark or other intellectual property right of any third
              party, or (ii) contain anything that is obscene, defamatory,
              harassing, offensive or malicious. You shall: (i) notify
              Company immediately of any unauthorized use of any password
              or user id or any other known or suspected breach of
              security, (ii) report to Company immediately and use
              reasonable efforts to stop any unauthorized use of the
              Service that is known or suspected, and (iii) not provide
              false identity information to gain access to or use the
              Services.
            </li>
          </ol>
          <p className="">
            If You violate any of the above terms, the Company, in
            addition to other rights that it may have in this Agreement or
            at law, shall be entitled to take actions such as including
            but not limited to removing or disabling access to material or
            the Software or Services.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Access to Software and Services
          </h3>
          <p className="">
            During the configuration and set-up process for each Software,
            You will identify an administrative user name and password for
            Your account on the Software. Company reserves the right to
            refuse registration of, or cancel usernames and passwords it
            deems inappropriate.
          </p>
          <p className="">
            You shall use, and will ensure that all use of the Services is
            in full compliance with this Agreement, and all applicable
            laws and regulations.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Your Representations</h3>
          <p className="">
            You represent and warrants that You (i) have accessed and
            reviewed any terms of use or other policies relating to a
            Software provided by Company or available on the Company
            website, (ii) understand the requirements thereof, and (iii)
            agree to comply therewith.
          </p>
          <p className="">
            Company may suspend Your account and access to Services and
            provision of the Services at any time and without notice if
            Company believes that You are in violation of this Agreement
            or any other applicable terms. Company reserves the right to
            monitor Your use of Services, and may prohibit any use it
            believes may be (or alleged to be) in violation of the
            foregoing.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Account Responsibility
          </h3>
          <p className="">
            You will be responsible for (i) all uses of any account that
            You have access to, and (ii) securing passwords (including but
            not limited to administrative and user passwords) and files.
            Company is not responsible for any losses, damages, costs,
            expenses or claims that result from stolen or lost passwords.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Privacy obligations</h3>
          <p className="">
            You hereby agree that Company’s provision of the Services may
            require Company to process, transmit and/or store Your
            personal data. By submitting personal data to Company, You
            agree that Company and its affiliates, agents, contractors may
            process, transmit and/or store personal data only to the
            extent necessary for, and for the sole purpose of, enabling
            Company to provide the Services under this Agreement. You
            agree to obtain all necessary consents and make all necessary
            disclosures before including any personal data in Your Data.
            You confirm that You are solely responsible for any personal
            data that may be contained in Your Data. You are solely
            responsible for determining the purposes and means of
            processing Your personal data by the Company under this
            Agreement. Prior to processing, You will inform Company about
            any special categories of data contained within Your Data and
            any restrictions or special requirements in the processing of
            such special categories of data, including any cross border
            transfer restrictions. You are responsible for ensuring that
            the Company meets such restrictions or special requirements.
            Company will process any personal data meeting the
            requirements set forth in this section according to Company’s
            Terms and Conditions and Privacy Policy which are incorporated
            herein by reference which policies may be changed from time to
            time and which You agree to have read, agreed and expressly
            consented and legally bound by the same. You are responsible
            for any security vulnerabilities, and the consequences of such
            vulnerabilities, arising from Your Data, including any
            viruses, Trojan horses, worms or other harmful programs
            contained in Your Data, or from Your use of the Services in a
            manner that is inconsistent with the terms of this Agreement.
            You may disclose or transfer, or instruct the Company to
            disclose or transfer, Your Data to a third party, and upon
            such disclosure or transfer Company will no longer be
            responsible for the security or confidentiality of such
            content and applications. You have the choice to not provide
            the Company access to health, payment card or similarly
            sensitive personal information that imposes specific data
            security obligations on the processing of such data which are
            greater than those specified in the Company Privacy Policy.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Third Party Services</h3>
          <p className="">
            The Services may enable You to link to, transmit Your Data to,
            or otherwise access third parties’ websites, platforms,
            content, products, services, and information. Company does not
            control and is not responsible for such third parties’
            websites, platforms, content, products, services, and
            information.
          </p>
          <p className="">
            Any Third Party Data which the Company makes accessible is
            provided on an “as-is” and “as available” basis without any
            warranty of any kind. You acknowledge and agree that Company
            is not responsible for, and has no obligation to control,
            monitor, or correct, Third Party Data. Company disclaims all
            liabilities arising from or related to the Third Party Data.
          </p>
          <p className="">
            You acknowledge and agrees that: (i) the nature, type, quality
            and availability of Third Party Data may change at any time
            during the Subscription Term, and (ii) features of the
            Services that interoperate with third parties such as
            Facebook™, YouTube™ and Twitter™, etc. (each, a “Third Party
            Service”), depend on the continuing availability of such third
            parties’ respective application programming interfaces (APIs).
            Company may need to update, change or modify the Services
            under this Agreement as a result of a change in, or
            unavailability of, such Third Party Data or Third Party
            Services or APIs. If any third party ceases to make its Third
            Party Data or APIs available on reasonable terms for the
            Services, as determined by Company in Company’s sole
            discretion, Company may cease providing access to the affected
            Third Party Data or Third Party Services without any liability
            to You. Any changes to Third Party Data, Third Party Services
            or APIs, including their unavailability, during the
            Subscription Term does not affect Your obligations under this
            Agreement, and You will not be entitled to any refund, credit
            or other compensation due to any such changes.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Updates</h3>
          <p className="">
            Company may from time to time and in its sole discretion
            develop and provide updates, which may include bug fixes,
            patches and other error corrections and/or new features to the
            Software. You acknowledge that updates may also modify or
            delete in their entirety certain features and functionality.
            You shall promptly download and install any updates and
            Company shall not be responsible for any damages, liabilities
            or claims arising or relating to non-installation of updates.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Consent to Use</h3>
          <p className="">
            You agree that Company may collect and use technical data and
            related information—including but not limited to technical
            information about Your device, system and application
            software, and peripherals—that is gathered periodically to
            facilitate the provision of software updates, product support,
            and other services to You regarding the Services.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Changes to Services</h3>
          <p className="">
            Company may, in its sole discretion, make any changes to
            Software that it deems necessary or useful to (i) maintain or
            enhance (a) the quality or delivery of Company’s products or
            services to its customers, (b) the competitive strength of, or
            market for, Company’s products or services, (c) such
            Software’s cost efficiency or performance, or (ii) to comply
            with any applicable law. Any such changes may not be
            applicable or available to You unless they are a part of Your
            subscription package.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Confidentiality</h3>
          <p className="">
            Confidential Information of Company includes the Software,
            Services and features, functionality thereof, any technical
            evaluation, reports and non-public information of the Company
            and any information which should reasonably be regarded by its
            nature or under the circumstances in which it was given as
            confidential.
          </p>
          <p className="">
            With respect to Confidential Information of the Company, You
            agree to: (i) use no less than a reasonable degree of care to
            protect the confidentiality, and prevent the unauthorized use
            or disclosure, of such Confidential Information, (ii) hold all
            such Confidential Information in strict confidence and not
            permit third party access or sell, copy, transfer, reproduce,
            or divulge such Confidential Information to any third party,
            (iii) not use such Confidential Information for any purposes
            whatsoever other than the performance of, or as otherwise
            authorized by, this Agreement.
          </p>
          <p className="">
            You acknowledge that breach of its obligation of
            confidentiality may cause irreparable harm to the Company for
            which the Company may not be fully or adequately compensated
            by recovery of monetary damages. Accordingly, in the event of
            any violation, or threatened violation, by You of Your
            obligations under this Section, the Company shall be entitled
            to seek injunctive relief from a court of competent
            jurisdiction in addition to any other remedy that may be
            available at law or in equity.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Intellectual Property Rights
          </h3>
          <p className="">
            You shall own all rights, title and interest in and to Your
            Data. Company shall own and retain all right, title and
            interest in and to Company Intellectual Property. To the
            extent You acquire any right, title or interest in any Company
            Intellectual Property, You hereby assigns all of its right,
            title and interest in such intellectual property to the
            Company.
          </p>
          <p className="">
            You hereby grant to Company a non-exclusive, transferable,
            sublicensable, worldwide and royalty-free license to host,
            copy, store, process, configure, perform, display, transmit
            and otherwise exploit Your Data to provide the Services to You
            hereunder and as necessary or useful to monitor and improve
            the Services, both during and after the Term. You shall be
            solely responsible for the accuracy, quality, integrity,
            legality, reliability, and appropriateness of Your Data, and
            for obtaining all rights related to Your Data as required by
            the Company to provide the Services.
          </p>
          <p className="">
            Company may use, reproduce and disclose Services related
            information, data and material that is anonymized,
            deidentified, or otherwise rendered not reasonably associated
            or linked to You or any other identifiable individual person
            or entity for product improvement and other lawful purposes,
            all of which information, data and material will be owned by
            Company. It is Your sole responsibility to back-up Your Data
            during the Term, and You acknowledge that You may not have
            access to Your Data through Company or any Software following
            the expiration or termination of this Agreement. No rights or
            licenses are granted to You except as expressly set forth
            herein.
          </p>
          <p className="">
            If you have access to Third Party Data through use of the
            Services, all ownership and intellectual property rights in
            and to Third Party Data and the use of such data will be
            governed by separate third party terms. Further, if any third
            party technology that may be appropriate or necessary for use
            with some Company programs is utilized by the Company, Your
            right to use such third party technology is governed by the
            terms of the third party technology license agreement.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Warranties and Disclaimer
          </h3>
          <p className="">
            Each party represents that it has validly entered into this
            Agreement and that it has the power and authority to do so.
            Company warrants that during the Term of the Agreement,
            Company will provide the Services using commercially
            reasonable care and under general industry standards and skill
            in all material respects. You represents that You a) own or
            has a license to use and has obtained all consents and
            approvals necessary for the provision and use of all of Your
            Data that is uploaded on or recorded on the Software b) the
            provision and use of Your Data does not and shall not violate
            any privacy policy, terms of-use or other agreement to which
            You may be a party or any law or regulation to which You are
            subject to. You agree that Company assumes no responsibility
            for Your Data provided on Software or availing the Services.
          </p>
          <p className="">
            COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE PERFORMED
            ERROR-FREE OR VIRUS FREE OR UNINTERRUPTED, THAT THE COMPANY
            WILL CORRECT ALL SERVICES ERRORS, OR THAT THE SERVICES WILL
            MEET YOUR REQUIREMENTS OR EXPECTATIONS OR AS TO THE RESULTS
            THAT MAY BE OBTAINED FROM USE OF THE SERVICES. COMPANY IS NOT
            RESPONSIBLE FOR ANY ISSUES RELATED TO THE PERFORMANCE,
            OPERATION OR SECURITY OF THE SERVICES THAT ARISE FROM YOUR
            DATA OR THIRD PARTY DATA OR SERVICES PROVIDED BY THIRD PARTIES
            AND ANY CLAIM BY YOU ARISING FROM OR RELATING TO SUCH THIRD
            PARTY SERVICES SHALL, BE SOLELY AGAINST SUCH THIRD PARTY
            SERVICE PROVIDER. COMPANY SHALL NOT BE LIABLE FOR UNAUTHORIZED
            ALTERATION, THEFT OR DESTRUCTION OF YOUR DATA, FILES, OR
            PROGRAMS. COMPANY DOES NOT CONTROL THE TRANSFER OF DATA OVER
            COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT
            THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER
            PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS
            FACILITIES.
          </p>
          <p className="">
            FOR ANY BREACH OF THE SERVICES WARRANTY, YOUR EXCLUSIVE REMEDY
            AND COMPANY’S ENTIRE LIABILITY SHALL BE THE CORRECTION OF THE
            DEFICIENT SERVICES THAT CAUSED THE BREACH OF WARRANTY, OR, IF
            COMPANY IS NOT ABLE TO SUBSTANTIALLY CORRECT THE DEFICIENCY IN
            A COMMERCIALLY REASONABLE MANNER, YOU MAY TERMINATE THE
            DEFICIENT SERVICES AND COMPANY WILL REFUND TO YOU(OR THE
            APPLICABLE LEGAL ENTITY TO WHOM YOU REPRESENT) THE FEES FOR
            THE TERMINATED SERVICES THAT HAS BEEN PREPAID TO THE COMPANY
            FOR THE PERIOD FOLLOWING THE EFFECTIVE DATE OF TERMINATION.{" "}
          </p>
          <p className="">
            THE SOFTWARE AND SERVICES ARE PROVIDED “AS IS,” AND COMPANY
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, IMPLIED WARRANTIES OF NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THIS SECTION SETS
            FORTH THE SOLE AND EXCLUSIVE WARRANTY GIVEN BY COMPANY
            (EXPRESS OR IMPLIED) WITH RESPECT TO THE SUBJECT MATTER OF
            THIS AGREEMENT.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Indemnity</h3>
          <p className="">
            You will indemnify, defend and hold harmless the Company and
            its affiliates, officers, directors, employees, agents,
            successors and assigns against and any losses, liabilities,
            deficiencies, claims, actions, judgments, settlements,
            interest, awards, penalties, fines, costs, or expenses of
            whatever kind, including attorneys' fees arising from or
            relating to (a) any of Your Data or Your use of Your Data with
            the Services, or any information, design, specification,
            instruction, software, service, data, hardware, or material
            furnished by You and/or used with the Services, infringes or
            misappropriates third party’s intellectual property rights, or
            (b) Your use of the Services in an unlawful manner or in
            violation of the Agreement.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Limitation of Liability
          </h3>
          <p className="">
            IN NO EVENT WILL COMPANY BE LIABLE FOR ANY INDIRECT,
            CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE, OR EXEMPLARY
            DAMAGES, OR ANY LOSS OF REVENUE, PROFITS, SALES, DATA, DATA
            USE, GOODWILL OR REPUTATION.
          </p>
          <p className="">
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF COMPANY AND ITS
            AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT,
            WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL
            AMOUNTS ACTUALLY PAID BY YOU FOR THE SERVICES GIVING RISE TO
            THE LIABILITY DURING THE TWELVE (12) MONTHS IMMEDIATELY
            PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Term and Termination</h3>
          <ol className=" nest-1">
            <li>
              The term of this Agreement shall begin when You
              download/install the Software or as per the date specified
              in Your subscription package and shall continue until
              terminated by either party.
            </li>
            <li>
              You may terminate this Agreement by deleting the Software
              from your desktop or mobile Device and closing your account.
            </li>
            <li>
              Company reserves the right to suspend or terminate the
              Services if You fail to timely pay any amounts due to
              Company under this Agreement. Company shall not be liable to
              You or to any third party for any liabilities, claims or
              expenses arising from or relating to suspension of the
              Services resulting from Your non-payment.
            </li>
            <li>
              Company reserves the right to suspend or terminate the
              Services if Company reasonably concludes that Your use of
              the Services is causing immediate or ongoing harm to Company
              or others. Company shall not be liable to You or to any
              third party for any liabilities, claims or expenses arising
              from or relating to any suspension of the Services in
              accordance with this para.
            </li>
          </ol>
        </li>
        <li>
          <h3 className="text-xs primary">
            Effect of Termination
          </h3>
          <p className="">
            Upon termination of this Agreement or expiration of the
            Subscription Term, Company shall immediately cease providing
            the Services and all usage rights granted under this Agreement
            shall terminate and You shall cease accessing or using the
            Services and delete the Software application from your desktop
            or mobile. The termination by the Company of this Agreement
            will not limit any of Company's rights or remedies at law or
            in equity.
          </p>
          <p className="">
            If Company terminates this Agreement due to a breach by You,
            then You shall immediately pay to Company all amounts then due
            under this Agreement.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Governing Law and Jurisdiction
          </h3>
          <p className="">
            This Agreement is governed by the laws of India and each party
            agrees to submit to the exclusive jurisdiction of, and venue
            in, the courts in Delhi in any dispute arising out of or
            relating to this Agreement.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Dependency on Third Party Technology
          </h3>
          <p className="">
            Company may, host and/or maintain the Software and/or provide
            the Service using a third party technology service provider
            and You agree that Company may not be able to offer any
            additional or modified procedures other than those put in
            place by such technology provider with respect to such
            technology service.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Force Majeure</h3>
          <p className="">
            Company is not responsible nor liable for any delays or
            failures in performance from any cause beyond its control,
            including, but not limited to acts of God, changes to law or
            regulations, embargoes, war, terrorist acts, acts or omissions
            of third party technology providers, riots, fires,
            earthquakes, floods, power blackouts, strikes, lockdowns,
            weather conditions or acts of hackers, internet service
            providers or any other third party or acts or omissions of
            You. Company will make commercially reasonable efforts to
            mitigate the effect of a force majeure event. If such an event
            continues for more than thirty (30) days, Company may cancel
            unperformed Services upon notice to You.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Assignment</h3>
          <p className="">
            You shall not assign this Agreement or any part thereof to any
            other party without the prior written consent of Company.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Cooperation</h3>
          <p className="">
            You shall provide all cooperation and assistance as Company
            may reasonably request to enable Company to exercise its
            rights and perform its obligations under.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Export</h3>
          <p className="">
            Export laws and regulations of the United States and any other
            relevant local export laws and regulations apply to the
            Services provided under this Agreement. Such export laws
            govern use of the Services (including technical data) and any
            Services deliverables provided under this Agreement, and
            parties agree to comply with all such export laws and
            regulations. You shall ensure that no data, information,
            software programs and/or materials resulting from Services (or
            direct product thereof) will be exported, directly or
            indirectly, in violation of these laws, or will be used for
            any purpose prohibited by these laws including, without
            limitation, nuclear, chemical, or biological weapons
            proliferation, or development of missile technology.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            No third party beneficiaries
          </h3>
          <p className="">
            This Agreement does not create any third party beneficiary
            relationships.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Compliance with Laws</h3>
          <p className="">
            You shall comply with all applicable laws in connection with
            the provision or use/access of the Services.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Relationship</h3>
          <p className="">
            Company is an independent contractor, and each party agrees
            that no partnership, joint venture, or agency relationship
            exists between the parties.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Notices</h3>
          <p className="">
            All notices and communications given under this Agreement
            shall be in writing and will be delivered by email or by post
            at the address provided by You when filling the subscription
            package and for the Company, at Company’s address available on
            Company website.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Severability</h3>
          <p className="">
            If any provision of this Agreement shall be found invalid or
            unenforceable, the validity, legality and enforceability of
            the remaining provisions shall not in any way be affected or
            impaired thereby.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Survival</h3>
          <p className="">
            All obligations to preserve the Confidential Information,
            Intellectual Property and other warranties and representations
            or any provision which by their nature should survive shall
            survive the termination of this Agreement.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Entire Agreement</h3>
          <p className="">
            This Agreement, together with the Terms and Conditions and
            Privacy Policy of the Company which is incorporated into this
            Agreement by written reference, constitutes the entire
            understanding and agreement of the parties with respect to its
            subject matter and supersedes all prior and contemporaneous
            agreements or understandings, inducements or conditions,
            express or implied, written or oral, between the parties.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">Waiver</h3>
          <p className="">
            The rights and remedies of each party under this Agreement may
            be exercised as often as necessary, are cumulative and not
            exclusive of rights or remedies provided by law, and may be
            waived only by specific written waiver. Delay in exercising or
            non-exercise of any such right or remedy does not constitute a
            waiver of that right or remedy, or any other right or remedy.
          </p>
        </li>
        <li>
          <h3 className="text-xs primary">
            Successors and Assigns
          </h3>
          <p className="">
            This Agreement binds and benefits the parties and their
            respective permitted successors and assigns.
          </p>
        </li>
      </ol>
    </div>
  </div>
  </div>
  )
}

export default Privacy