import { Routes, Route } from "react-router-dom";
import React from 'react';
import { lazy } from "react";
import Home from "../Components/Home/index"
import About from '../Components/Pages/Aboutus/Aboutus'
import ContactUs from '../Components/Pages/Contactus/Contactus'
import Career from '../Components/Pages/Career/Career'
import OurBusiness from '../Components/Pages/Ourbusiness/Ourbusiness'
import Faq from '../Components/Pages/Faq/index'
import Terms from "../Components/Pages/Links/Terms";
import Cookies from "../Components/Pages/Links/Cookies";
import Privacy from "../Components/Pages/Links/Privacy";
import PageNotFound from "../Components/Pages/PageNotFound/PageNotFound";
// const Home = lazy(() => import("../Components/Home/index"));
// const About = React.lazy(() => import('));
// const ContactUs = lazy(() => import(""));
// const Career = lazy(() => import(""));
// const OurBusiness = lazy(() => import("../Components/Pages/Ourbusiness/Ourbusiness"));
// const Faq = lazy(() => import("../Components/Pages/Faq/index"));

import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const AppRoutes = () => {

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      {/* <Route  path="/home" element={<Home />}  exact/>  */}
      <Route path="/about" element={<About />} exact />
      <Route path="/contact" element={<ContactUs />} exact />
      <Route path="/career" element={<Career />} />
      <Route path="/our-business/:_id" element={<OurBusiness />} exact />
      <Route path="/our-business" element={<OurBusiness />} exact />
      <Route path="/faq" element={<Faq />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/cookies" element={<Cookies />} />
      <Route path="/privacy" element={<Privacy />} />
      {/* <Route path="*" element={<Home />} /> */}
      <Route path="/*" element={<PageNotFound />} />
    </Routes>

  )


}

export default AppRoutes