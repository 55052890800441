import React, { useState } from "react";
import "./home.scss";
import { motion } from "framer-motion";

import HeroImage from "./heroImage.png";
import client1 from "../../Assets/images/client1.png";
import client2 from "../../Assets/images/client2.png";
import client3 from "../../Assets/images/client3.png";
import client4 from "../../Assets/images/client4.png";
import client5 from "../../Assets/images/client5.png";
import client6 from "../../Assets/images/client6.png";
import OurBusinessSection from "./OurBusinessSection/OurBusinessSection";
import AboutUs from "./AboutusSection/AboutusSection";
import JointTeamSection from "./JoinTeamSection/JointTeamSection";
import ContactSection from "./Contactsection/ContactSection";
import CustomerReviews from "./CustomerReview/CustomerReview";
import AboutusSection from "./AboutusSection/AboutusSection";
import FormPopup from "./FormPopup/FormPopup";
const Home = () => {
  const clinetImagedata = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
  ];
  const [openContactPopup, setOpenContactPopup] = useState(false);

  const openContactpopup = () => {
     
    setOpenContactPopup(!openContactPopup);
    document.body.classList.add('avoidscroll');
  
  };
  const closeContactpopup = () => {
    setOpenContactPopup(false);
    document.body.classList.remove('avoidscroll');
  };
  return (
    <React.Fragment>
      <div className="home-wrapper">
        <section className="hero-section">
          <div className="container">
            <div className="hero-container">
              <div
                className="hero-leftSidebar"
              >
                <p className="bneed-p">BNEED</p>
                <div className="heading-wrap">
                <h1 className='hero-heading'>Reinventing the ways of Doing Business with Digitalization</h1>

                </div>
                <div className="subheading-wrap">
                <p className='subheading'>The B-Need explores the ways to transform your business and generate fortunes with the help of Digitalization.</p>

                </div>
                <div className="button-wrap">
                  <button
                    className="button btn-oval btn-o-primary"
                    onClick={openContactpopup}
                  >
                    CONTACT US
                  </button>
                </div>
                {/* open form on click */}
                {openContactPopup && (
                  <FormPopup closeContactpopup={closeContactpopup} />
                )}
              </div>
              <div 
                       className="hero-rightSidebar">
                <div className="image-wrap">
                  <img src={HeroImage} alt="heroImage" />
              </div>
                </div>
            </div>
          </div>
        </section>

        {/* client logo section */}
        <section className="clientLogo-section section-topgap">
          <div className="conatiner-fluid">
            <div className="clientSection-container">
              <div className="container">
                <div className="client-div">
                  {clinetImagedata.map((data, index) => (
                    <div className="client-img-wrap" key={index}>
                      <img src={data} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Business section */}
        <section className="ourBusinessSection section-topgap">
          <OurBusinessSection />
        </section>
        <section className="aboutusSection section-topgap">
          <AboutusSection />
        </section>

        {/* customer review */}
        <section className="customerSection section-topgap">
          <CustomerReviews />
        </section>

        {/* join our team section */}
        <section className="joinTeamSection section-topgap ">
          <JointTeamSection />
        </section>

        {/* contact us section */}
        <section className="contactusSection  section-topgap ">
          <ContactSection />
        </section>
      </div>
    </React.Fragment>
  );
};

export default Home;
