import React, { useState } from "react";
import "./edneed.scss";
import BrandneedImage from "./brandneed.png";
import FeatureImage1 from "../../../../Assets/images/feature1.png";
import FeatureImage2 from "../../../../Assets/images/featue2.png";
import FeatureImage3 from "../../../../Assets/images/feature3.png";
import c1 from "./brandneed-client-1.png";
import c2 from "./brandneed-client-2.png";
import c3 from "./brandneed-client-3.png";
import c4 from "./brandneed-client-4.png";
import c5 from "./brandneed-client-5.png";
import FormPopup from "../../../Home/FormPopup/FormPopup";
const Brandneed = () => {
  const [openContactPopup, setOpenContactPopup] = useState(false);

  const openContactpopup = () => {
    setOpenContactPopup(!openContactPopup);
  };
  const closeContactpopup = () => {
    setOpenContactPopup(false);
  };
  return (
    <React.Fragment>
    <div className="innovation-wrap">
    <div className="container">
        <div className="invoation-leftSidebar">
          <h3 className="invoation-heading">
          Innovating ways to build <br/>and establish brands
          </h3>
          <p className="invoation-para">
          The Brandneed is the ultimate destination for all the requirements that your Brand needs. We, at Brandneed, offer the best marketing solutions that can instantly boost your brand’s reputation and skyrocket its growth. The Brandneed can uplift your brand’s reputation in no time.

          </p>
          <div className="edneed-btn-wrap">
            <a href="https://thebrandneed.com/"  rel="noreferrer" target={"_blank"} className="button btn-oval btn-o-primary ">
              VISIT NOW
            </a>
          </div>
        </div>
        <div className="invoation-rightSidebar">
          <img src={BrandneedImage} alt="" />
        </div>
      </div>
      </div>
    <div className="container edneed-container">
      <div className="our-feature-wrap">
        <p className="feature-p">OUR FEATURES</p>
        <div className="feature-content-wrap">
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage1} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Social Media Marketing 
              </h4>
              <p className="feature-content-p">
              Boost your online visibility through Brandneed’s social media marketing. Get unrivaled Social media marketing strategy from our marketing experts. Engage new customers and retain the current ones through attractive social media posts. Unlock your brand’s growth with Brandneed.
              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage2} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Search Engine Optimization 
              </h4>
              <p className="feature-content-p ">
              Climb up the google ranks with Brandneed’s search engine optimization. Start ranking your website on google search results, get meta description and meta tags along with the added feature of backlinking to boost your website’s ranking on search engine results.

              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage3} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Logo &<br /> Website Builder
              </h4>
              <p className="feature-content-p ">
              Reinvent your brand’s Identity with our logo builder. Exhibit your brand’s quality and the services that you provide by creating a dynamic and alluring website. A good logo serves as a brand ambassador for your brand, people always remember a good logo. A website can increase your brand’s credibility significantly you can attract potential customers with a website. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-container">
        <div className="partner-leftSidebar">
          <p className="brand-p">BRANDS</p>
          <h4 className="client-heading">
            Clients and <br />
            Frequent <br /> Partners
          </h4>
          <p className="client-subheading">
          Helping your organization to reach its potential, driving bold ideas and pragmatic solutions for optimum outcomes.          </p>
        </div>
        <div className="partner-rightSidebar">
          <div className="parner-images-container">
          <div className="image-div ">
              <img src={c1} alt="" />
            </div>
            <div className="image-div ">
              <img src={c2} alt="" />
            </div>
            <div className="image-div ">
              <img src={c3} alt="" />
            </div>
            <div className="image-div ">
              <img src={c4} alt="" />
            </div>
            <div className='image-div '>
              <img src={c5} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-parnet-wrap">
        <button className="button btn-oval btn-o-primary" type="button" onClick={openContactpopup}>CONTACT US</button>
      </div>
    </div>
    {openContactPopup && (
                  <FormPopup closeContactpopup={closeContactpopup} />
                )}
    </React.Fragment>
  );
};

export default Brandneed;
