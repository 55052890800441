import React, { useEffect, useState } from "react";
import "./formPopup.scss";
import FacebookIcon from '../../../Assets/images/facebook.png';
import linkedinIcon from '../../../Assets/images/linkedin.png';
import TwitterIcon from '../../../Assets/images/twitter.png';
import YoutubeIcon from '../../../Assets/images/youtube.png';
import InstagramIcon from '../../../Assets/images/insta.png';
import axios from "axios";
import ValidationFile from "../../../Classes/ValidationFile";

const FormPopup = ({ closeContactpopup }) => {
  const [name,setName] = useState('') 
  const [success,setSuccess]=useState(false)
    const [nameError,setNameError]=useState(false)
    const [email,setEmail] = useState('') 
    const [message,setMessage] = useState('') 
    const [emailError,setEmailError]=useState('')
    const handlechange = (e)=> {
        let inputName = e.target.name
        let inputValue = e.target.value
        setSuccess(false)
        switch(inputName){
            case "name":
                setName(ValidationFile.spaceNotAccept(inputValue))
                setNameError(false)
                break;
                case "email":
                    setEmail(inputValue)
                break;
                case "message":
                    setMessage(inputValue)
                break;
                default:
                    return false
        }
    }
    const validEmail = () => {
        let isValid = true;
        if (email) {
          if (!ValidationFile.validEmail(email)) {
            isValid = false;
            setEmailError(true)
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
        }
        return isValid;
      }; 
      const data = ()=>{
        return{
            name:name,
            message:message,
            email:email,
            
        }
      }
      const submit = (e)=>{
        e.preventDefault()
        if(!ValidationFile.isEmail(email)){
          setEmailError(true)
        }
        if(ValidationFile.isEmpty(name)){
          setNameError(true)
        }
        if(ValidationFile.isEmail(email) && ValidationFile.isNotEmpty(name)){

            axios.post('https://commonservices.edneed.com/bneedCommunication/contact-request',data() )
              .then(function (response) {
                setSuccess(true)
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      }
      useEffect(()=>{
        if(success){
          setName("")
          setEmail("")
          setMessage("")
        }
      },[success])
  return (
    <div className="formpopup-container">
      <div className="form-header-wrap">
        <div className="title-wrap">
          <p className="title-text">CONTACT US</p>
        </div>
        <div className="icon-wrap">
          <i className="icon-cross" onClick={closeContactpopup}></i>
        </div>
      </div>

      <div className="formpop-body">
        <p className="body-top-text">
          If you want to get free consultation without any obligation, fill in
          the form below and we’ll get in touch with you
        </p>
        <div className="form-wrapper">
          <form action="" onSubmit={submit}>
            <div className="formwrap">
              <label for="name" className="label-form">Full Name</label>
              <input type="text"  id="name" value={name} name="name" onChange={(e)=>handlechange(e)} onKeyUp={(e)=>handlechange(e)} className="form-input" placeholder="Your name"/>
              {nameError&& <p className="input_error">Name required.</p>}
            </div>
            <div className="formwrap">
              <label for="email"  className="label-form">Email</label>
              <input type="eamil" id="email" name="email" value={email}  className="form-input" placeholder="Your email"  onChange={(e)=>handlechange(e)} onKeyUp={(e)=>handlechange(e)}/>
              {emailError&& <p className="input_error">Email required.</p>}
            </div>
            <div className="formwrap">
              <label for="message"  className="label-form">Message</label>
              <textarea id="message" name="message" rows="6" value={message} placeholder="Your message"  onChange={(e)=>handlechange(e)} onKeyUp={(e)=>handlechange(e)}>
              </textarea>
            </div>
            <div className="form-btn-wrap">
            {
              success?
              <button className="button btn-block btn-blue" type="button">SENT</button>
            : <button className="button btn-block btn-blue" type="submit">SEND MESSAGE</button>
             }
            
            </div>
          </form>
        </div>
        <div className="socail-icon">
        <div className="icon">
          <a href="https://www.facebook.com/people/Bizneed/100087233431116/">
            <img src={FacebookIcon} alt="" />
            </a>
        </div>
        {/* <div className="icon">
            <img src={linkedinIcon} alt="" />
        </div> */}
        {/* <div className="icon">
            <img src={TwitterIcon} alt="" />
        </div> */}
        {/* <div className="icon">
            <img src={YoutubeIcon} alt="" />
        </div> */}
        <div className="icon">
          <a href="https://www.instagram.com/biz.need/?igshid=YmMyMTA2M2Y%3D">
            <img src={InstagramIcon} alt="" />
            </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FormPopup;
