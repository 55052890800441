import React from "react";
import "./ourBusinessSection.scss";
import Count1 from "../../../Assets/images/count1.png";
import Count2 from "../../../Assets/images/count2.png";
import Count3 from "../../../Assets/images/count3.png";
import Edneed from "./edneed.png";
import Brandneed from "./brandneed.png";
import Webneed from "./webneed.png";
import BusinessImage from "../../../Assets/images/businessImage.png";
import { motion } from "framer-motion";

const OurBusinessSection = () => {
  // for animation
  const variantsLeft = {
    start: {},
  };
  return (
    <div className="container">
      <h1 className="main-heading">
        Our Business <br />
        Verticals
      </h1>
      <div className="ourBusiness-container margin-72 ">
        <div className="busineess-leftSidebar fistSectionPadding">
          <div className="number-conunt">
            <div
              className="image-wrap"
            >
              <img src={Count1} alt="" />
            </div>
            <div
              className="count-text"
            >
              <h4 className="heading-count">Webneed</h4>
            </div>
          </div>
          <div className="content-wrap">
            <p className="content-text">
            Webneed provides unparalleled business solutions that have the potential to significantly increase your company’s growth and reputation. Webneed is the best platform for you, which offers services like website building, e-commerce, booking services, SEO, etc that can rapidly increase your company’s value.
            </p>
            <div className="content-btn-wrap">
              <a href="/our-business/webneed" className="button btn-oval btn-o-primary" >
                VIEW MORE
              </a>
            </div>
          </div>
        </div>
        <div className="v-line"></div>
        <div className="busineess-rightSidebar">
           <div
                className="business-wrap">
                <img src={Webneed} alt="" />
          </div>
        </div>
      </div>
      {/* 2nd */}
      <div className="ourBusiness-container ">
        <div className="busineess-rightSidebar padding-80 ">
          <div
              className="business-wrap mr">
            <img src={Brandneed} alt="" />
             </div>
        </div>
        <div className="v-line"></div>
        <div className="busineess-leftSidebar padding-80">
          <div className="number-conunt">
            <div
               className="image-wrap ">
                <img src={Count2} alt="" />
            </div>
            <div
            className="count-text">
              <h4 className="heading-count">The BrandNeed</h4>
            </div>
          </div>
          <div className="content-wrap">
            <p className="content-text">
            The Brandneed is the ultimate destination for all the requirements that your Brand needs. We, at Brandneed, offer the best marketing solutions that can instantly boost your brand’s reputation and skyrocket its growth. The Brandneed can uplift your brand’s reputation in no time.
            </p>
            <div className="content-btn-wrap">
              <a href="/our-business/thebrandneed" className="button btn-oval btn-o-primary">
              VIEW MORE
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 3rd */}
      <div className="ourBusiness-container">
        <div className="busineess-leftSidebar padding-80">
          <div className="number-conunt">
            <div
             className="image-wrap">
              <img src={Count3} alt="" />
            </div>
            <div
               className="count-text">
              <h4 className="heading-count">Edneed</h4>
            </div>
          </div>
          <div 
          className="content-wrap">
            <p className="content-text">
            Edneed provides a comprehensive and advanced platform to educators and learners worldwide. We, at Edneed, are continuously redefining the ways of online education through our revolutionary products such as LMS(learning management system) and ERP(enterprise resource planning.) 
            </p>
            <div className="content-btn-wrap">
              <a href="/our-business/edneed" className="button btn-oval btn-o-primary">
              VIEW MORE
              </a>
            </div>
          </div>
        </div>
        <div className="v-line"></div>
        <div className="busineess-rightSidebar padding-80">
          <div
             className="business-wrap">
            <img src={Edneed} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBusinessSection;
