import React, { useEffect, useState } from "react";
import "./joinTeamSection.scss";
import { motion } from "framer-motion";
import ValidationFile from "../../../Classes/ValidationFile";
import axios from "axios";
import { Link } from "react-router-dom";
const JointTeamSection = () => {
  const [email,setEmail]=useState("")

  const [emailError,setEmailError]=useState("")
  const [success,setSuccess]=useState(false)
  const handleChange = (e)=>{
    setSuccess(false)
    setEmail(ValidationFile.spaceNotAccept((e.target.value)))
    setEmailError(false)
  }
  const submit =()=>{
      if( ValidationFile.isEmail(email)){

          axios.post('https://commonservices.edneed.com/bneedCommunication/subscription-request', { email:email}, )
            .then(function (response) {
              setSuccess(true)
            })
            .catch(function (error) {
              console.log(error);
            });
      
    }else{
      setEmailError(true)
    }
  }
  useEffect(()=>{
    if(success){
      setEmail("")
    }
  },[success])
  return (
    < div className="container-fluid">
      <div
        className="joinTeam-container"
      >
        <div className="joinTeam-wrapper">
          <p className="career-p">CAREER</p>
          <div className="team-content-wrap">
            <div className="content-leftSidebar">
            <h4 className='team-heading'>Join Our Team</h4>
                   <p className='team-para'>B-Need is a place for innovators, thinkers, and creators. <br/>We aim for growth and progress and if you share the same values then B-Need is right the place for you.</p>
            </div>
            <div className="content-rightSidebar">
              <Link to={'/career'}>
                 <div className="circle">
                   <i className="icon-right"></i>
                 </div></Link>
             
            </div>
          </div>
        </div>
        <div className=" subscribe-wrapper">
          <p className="career-p">NEWSLETTER</p>
          <div className="team-content-wrap">
            <div className="content-leftSidebar">
              <h4 className="team-heading">Subscribe us</h4>
              <p className="team-para">
                Subscribe to our newsletter and stay updated
              </p>
            </div>
            <div className="subscribe-input-wrap">
              <div className="group">
              <input type="email" name="email" className="form-input" onKeyUp={(e)=> handleChange(e)} value={email} placeholder="Enter a vaild email id" onChange={handleChange}/>
            {emailError&&<p className="input_error">Email required.</p>}
              </div>
             {
              success?
              <button type="button" className="button btn-oval btn-o-primary">Submitted</button>
            :<button type="button" onClick={submit} className="button btn-oval btn-o-primary">SUBMIT</button>
             }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JointTeamSection;
