import React from "react";
import "./contactus.scss";
import MapImage from '../../../Assets/images/map.png';
import Form from '../../../Common/CommonJsx/Form/Form'

const Contactus = () => {
  return (
   
      <div className="contact-container pageBottomgap padding-top">
         <div className="container">
        <h1 className="main-heading">Contact us</h1>
        <div className="office-address-wrapper">
          <div className="address-wrap">
            <h3 className="office-location">USA Office</h3>
            <p className="phone-text">+1 (254) 640-3448</p>
            <p  className="address">
            9009 North PLAZA UNIT 141, City- Austin, State - Texas- 78753
            </p>
          </div>
          <div className="address-wrap">
            <h3 className="office-location">Noida Office</h3>
            <p className="phone-text">+91 8368214889</p>
            <p  className="address">
              203, Tower C, Ithum Towers, Sector 62, Noida, Uttar Pradesh 201309
            </p>
          </div>
          <div className="address-wrap">
            <h3 className="office-location">Manipur Office</h3>
            <p className="phone-text">+91 8368212543</p>
            <p className="address">
            2nd Floor Gypsy Building, Paona International Market,Paona Bazar, Imphal west, Manipur - 795001
            </p>
          </div>
        </div>
        <div className="contactus-mapSection">
            <div className="map-wrap">
            <iframe  title="Edneed Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.112132980793!2d77.37013491440713!3d28.626401191110645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce563f95b5bd1%3A0x4d091c1f9b830f7b!2sEdneed%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1666297603598!5m2!1sen!2sin" width="100%" height="575" style={{"border" : "none"}}  loading="lazy"></iframe>
            </div>
            <div className="form-wrap">
                <h1 className="getIntouch">GET IN TOUCH</h1>
                <Form />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Contactus;
