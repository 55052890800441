import React from 'react'
import Logo from "../../Assets/images/logo1.png";
import Facebook from "../../Assets/icons/SocialIcon/facebook.png";
import Instagram from "../../Assets/icons/SocialIcon/instagram.png";
import Twitter from "../../Assets/icons/SocialIcon/twitter.png";
import "./footer.scss";
import { NavLink } from 'react-router-dom';
const Footer = () => {
  return (
    <footer>
    <div className='container'>
    <div className="footer-wrapper">
    <div className="footerHero">
  <div className="HeroItem">
      <div className='logo-wrapper'>
     <img className='logo' src={Logo} alt="" />
    </div>
    <p className='hero-text'>
    B-Need is the one-stop platform that has consolidated the expertise of fields of education, marketing, and business solutions in a single place. We offer products and services that help your business grow multifold.    
    </p>
  </div>
  <div className="HeroItem">
      <h6>Company</h6>
      <ul className='heroItemList'>
        <li className='listItem'>
          <a href="https://webneed.io/" target="_blank"rel="noreferrer">Webneed</a>
        </li>
        <li className='listItem'>
          <a href="https://edneed.com/" target="_blank" rel="noreferrer">Edneed</a>
        </li>
        <li className='listItem'>
          <a href="https://thebrandneed.com/" target="_blank"rel="noreferrer">Brandneed</a>
        </li>
      </ul>
  </div>
  <div className="HeroItem">
      <h6>Quick Links</h6>
      <ul className='heroItemList'>
        <li className='listItem'>
          <NavLink to="/career">Careers</NavLink>
        </li>
        <li className='listItem'>
          <NavLink to="/about">About us</NavLink>
        </li>
        <li className='listItem'>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        {/* <li className='listItem'>
          <a href="/faq" rel="noreferrer">FAQs
</a>
        </li> */}
      </ul>
  </div>
  <div className="HeroItem">
      <h6>Follow us on</h6>
      <ul className='social-handle-wrapper'>
        <li className='social-handle-item'>
          <a href="https://www.facebook.com/people/Bizneed/100087233431116/" target="_blank"rel="noreferrer"><img src={Facebook} alt=""/></a>
        </li>
        {/* <li className='social-handle-item'>
        <img src={Twitter} alt=""/>
           <a href="/" target="_blank" rel="noreferrer"><img src={Twitter} alt=""/></a> 
        </li> */}
        <li className='social-handle-item'>
          <a href="https://www.instagram.com/biz.need/?igshid=YmMyMTA2M2Y%3D" target="_blank"rel="noreferrer"><img src={Instagram} alt=""/></a>
        </li>
      </ul>
      <h6 className='mt-10 conatc-us-heading'>Contact Us</h6>
         <div className='address-wrap-footer'>
                <div className='icon-wrap-footer'>
                    <i className='icon-call'></i>
                    <p>+91-83682 14889</p>
                </div>
                <div className='icon-wrap-footer'>
                    <i className='icon-mail'></i>
                    <p>support@b-need.com</p>
                </div>
                <div className='icon-wrap-footer margin-topp'>
                    <i className='icon-loaction'></i>
                    <p> <b>India : </b> 203, Tower C, Ithum Towers, <br/> Sector
                         62, Noida, Uttar Pardesh 201309</p>
                </div>
                <div className='icon-wrap-footer margin-topp'>
                    <i className='icon-loaction'></i>
                    <p> <b>USA : </b> 9009 North Plaza, Unit 141 Austin Texas 78753</p>
                </div>
            </div>
  </div>
  </div>
  <div class="footer-last">
  <div className='divider'></div>
      <div className='footer-highlights'>
        <ul className='highlights-menu'>
          <li className='highlights-menu-item'>
          <NavLink to="/terms" >Terms</NavLink>
          </li>
          <li className='highlights-menu-item'>
          <NavLink to="privacy">Privacy</NavLink>
          </li>
          <li className='highlights-menu-item'>
          <NavLink to="cookies">Cookies</NavLink>
          </li>
          <li className='highlights-menu-item'>
          <a href="sitemap.xml" target="_blank">Sitemap</a>
          </li>
        </ul>
        <p>Copyright@2024 Edneed. All rights reserved</p>
      </div>
  </div>
    </div>
    </div>
  </footer>
  )
}

export default Footer