import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Brandneed from './Brandneed';
import Edneed from './Edneed';
import './ourbusiness.scss';
import Webneed from './Webneed';

const Ourbusiness = () => {
  const [currentTab,setCurrentTab] = useState(1);
  const handleCurretTab = (tab)=> {
    setCurrentTab(tab)
  }
  const {_id}=useParams()
  
useEffect(()=>{
  if(_id){
    if(_id==="webneed") {
      setCurrentTab(1)
      // window.scrollTo(0,300)
    }
    else if (_id==="thebrandneed") {
      setCurrentTab(2)
      // window.scrollTo(0,300)
    }
    else if (_id==="edneed") {
      setCurrentTab(3)
      // window.scrollTo(0,300)
    }
   window.location.replace(`#${_id}`)
  
  }

  
},[_id])
  return (
    <div className='ourbusiness-container pageBottomgap padding-top'>
       <div className='container'>
        <div className='top-heading-wrap text-center'>
          <h1 className='main-heading'>Our Business</h1>
           <p className='business-top-para'>The B-NEED seeks to close the technical gap in numerous business sectors. We have expertise in education, marketing, business, services, and the e-commerce sector.  We believe that with the power of digitalization we can transform the ways businesses are operating currently and can help them to reach their true potential.
</p>
        </div>
        {/* <button onClick={() => window.location.replace("#tab")}>Click Me</button> */}

          <div className='tab-container' id={_id}>
            <button className={`button btn-tab ${currentTab===1 ? 'tab-active':''}`} onClick={()=>{handleCurretTab(1)}}>Webneed</button>
            <button className={`button btn-tab ${currentTab===2 ? 'tab-active':''}`}  onClick={()=>{handleCurretTab(2)}}>The Brandneed</button>
            <button className={`button btn-tab ${currentTab===3 ? 'tab-active':''}`}  onClick={()=>{handleCurretTab(3)}}>Edneed</button>
          </div>
         </div>
          <div className='tab-content-container' >
            <div className={`${currentTab===1  ? 'show':'displayHide'}`}>
               <Webneed />
            </div>
            <div className={`${currentTab===2  ? 'show':'displayHide'}`}>
            <Brandneed />
             </div>
             <div className={`${currentTab===3  ? 'show':'displayHide'}`}>
             <Edneed />
             </div>
          </div>
    </div>
  )
}

export default Ourbusiness