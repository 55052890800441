import React from "react";
import "./aboutus.scss";
import { motion } from "framer-motion";
const AboutusSection = () => {
  return (
    <div className='container'>
        <div className='aboutus-container'>
        <h1
          className="main-heading"
        >About us </h1>
       <p
          className="text-center aboutus-p">
        B-NEED seeks to close the technical gap in numerous business sectors. We ensure great outcomes, support various businesses and services in expanding, and enable them to realize their full potential because we possess the experience, knowledge, and access to the necessary technology. We are the B-NEED, and when it comes to meeting all of your business demands, we are unrivaled.  
        </p>
    </div>
    </div>
  );
};

export default AboutusSection;
