import React, { useRef, useState } from 'react';
import ApplyPopup from '../../../Common/ApplyPopup/ApplyPopup';
import Pagination from '../../../Common/CommonJsx/Pagination/Pagination';
import './career.scss';

const Career = () => {
  const[getCurrentValue,setCurrentValue] = useState('All');

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()  

  const position = ['All','Sales team','Graphics team','Digital team ','Content team'];

  const PostionDetails = [
    {
    id:1,
    positionName:'Business Development Executive',
    details:'Bneed is currently looking to hire an enthusiastic, energetic and ambitious Business Development Executive to join our Sales team and build a strong portfolio of clients.',
    positionType: 'Sales team',
    location:'Noida',
    jobType:'Full-time'

  },
  {
    id:2,
    positionName:'Motion Graphics Designer',
    details:'Bneed is currently hiring a Motion Graphics Designer who will work closely with the marketing team to brainstorm and create high quality video content that reflect branding priorities.',
    positionType: 'Graphics team',
    location:'Noida',
    jobType:'Full-time'
  },
  {
    id:3,
    positionName:'Google Ads Specialist',
    details:'Bneed is looking for Google Ads Specialist that plans, sets up and manages campaigns in Google Ads. We’re a forward-thinking IT company looking for a talented and Passionate person.',
    positionType: 'Digital team',
    location:'Noida',
    jobType:'Full-time'
  },

  {
    id:4,
    positionName:'Content Writer',
    details:'Bneed is currently looking to hire an enthusiastic, energetic and ambitious Content Writer to join our DM team and build a strong portfolio of clients.',
    positionType: 'Content team',
    location:'Noida',
    jobType:'Full-time'
  },

]

// filte the job array by postion
const FilterArray = PostionDetails.filter(({id,positionName,details,positionType})=> {
   if(getCurrentValue==='All') {
    return true
   }
   else {
    return positionType.toLowerCase().trim() === getCurrentValue.toLowerCase().trim()
   }
}
)



// get current postion of the tab
  const [currentPostion,setCurrentPostion] = useState(0);
  const handleCurentSelected = (index,position) => {
    setCurrentPostion(index)
    // set the current tab value of tab and filter the array
    setCurrentValue(position)

  }

  const[openPopup,setOpenPopup] = useState(-1);

  const handleOpenpopUp = (id) => {
    setOpenPopup(id===openPopup? -1 : id);
    // console.log(id)

  }

  const handleClosePopup = ()=> {
    setOpenPopup(false);
  }

    
  return (
    <div className='career-container pageBottomgap padding-top'>
        <div className='container'>
            <h1 className='main-heading'>Work with us</h1>
            <div className="career-top-text-wrap">
              <p className='career-top-text'>The B-Need has brought together experts from the field of marketing, business, e-commerce, and education in a single place to create an unrivaled platform that has the ability to fulfill the needs of all kinds of businesses or services. 
               The B-Need has the best minds working collectively to explore and invent different kinds of strategies that can boost various businesses and services. 
                We are a SaaS-based company,  The B-Need is a place for innovators, thinkers, and creators. We acknowledge the potential that digitalization possesses and that’s why we have been helping businesses by providing our expertise in digitalization and how to utilize it effectively. B-Need aims for growth and progress and if you too share the same values then B-Need is right the place for you.
                </p>
            </div>
            <div className='career-top-btn-wrap  text-center'  ref={myRef}>
              <button className='button btn-oval btn-primary' onClick={executeScroll}>We’re hiring</button>
            </div>
        </div>
        <div className='container'>
          <div className='openPosition-container'>
            <h4 className='openPostion-text'>Open Positions</h4>
            <p>We're currently looking for help in the following areas:</p>
              <div className='button-switch-wrap'> 
              {
                position.map((position,index)=> (
                  <button className={`switch-wrap-btn ${index=== currentPostion ? 'btn-oval btn-primary' : 'btn-inactive'}`} key={index} onClick={()=>{handleCurentSelected(index,position)}}>{position}</button>
                ))
              }
                
                 {/* <button className='button '>View  All</button>
                 <button className='button '>View  All</button>
                 <button className='button '>View  All</button>
                 <button className='button '>View  All</button>
                 <button className='button '>View  All</button>
                 <button className='button '>View  All</button>
                 <button className='button '>View  All</button> */}
          </div>
          <> 
          {
            FilterArray.length>0 ? (
              FilterArray.map((options,index)=> (
                <React.Fragment>
                 <div className='openPosition-warp'>
                  <div className='openPosition-lefside'>
                   <h4 className='openPosition-title'>{options.positionName}</h4>
                   <p className='openPosition-details'>{options.details}</p>
                   <div className='jobType-wrap'>
                  <button className='jobtype-btn'>
                    <i className='icon-loaction'></i> <span>{options.location}</span>
                  </button>
                  <button className='jobtype-btn'>
                    <i className='icon-watch'></i> <span>{options.jobType}</span>
                  </button>
                   
                 </div>
                 </div>
                  <div className='openPosition-rightside'>
                  <button className='button btn-oval btn-o-primary btn-sm' onClick={()=>handleOpenpopUp(options.id)}>Apply</button>
                  </div>
                  {/* open pop up */}
                  {
                    openPopup===options.id && <ApplyPopup title={options.positionName} handleClosePopup= {handleClosePopup} />
                  }
                 </div>
                </React.Fragment>
              ))
            ): (
              <p>No data found</p>
            )

             }
            </>
          </div>
         
        </div>
        {/* <div className='pagination-wrap'>
          <Pagination />
        </div> */}
    </div>
  )
}

export default Career