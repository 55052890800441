import React, { useState } from "react";
import "./edneed.scss";
import WebneedImage from "./webneed.png";
import { Navigate } from "react-router-dom";
import FeatureImage1 from "../../../../Assets/images/feature1.png";
import FeatureImage2 from "../../../../Assets/images/featue2.png";
import FeatureImage3 from "../../../../Assets/images/feature3.png";
import c1 from "./webneed-client-1.png";
import c2 from "./webneed-client-2.png";
import c3 from "./webneed-client-3.png";
import c4 from "./webneed-client-4.png";
import c5 from "./webneed-client-5.png";
import c6 from "./webneed-client-6.png";
import FormPopup from "../../../Home/FormPopup/FormPopup";
const Webneed = () => {
  const [openContactPopup, setOpenContactPopup] = useState(false);

  const openContactpopup = () => {
    setOpenContactPopup(!openContactPopup);
  };
  const closeContactpopup = () => {
    setOpenContactPopup(false);
  };
  return (
    <React.Fragment>
    <div className="innovation-wrap">
    <div className="container">
        <div className="invoation-leftSidebar">
          <h3 className="invoation-heading">
          Reputation of providing <br/> the best business solutions
          </h3>
          <p className="invoation-para">
          The Brandneed is the ultimate destination for all the requirements that your Brand needs. We, at Brandneed, offer the best marketing solutions that can instantly boost your brand’s reputation and skyrocket its growth. The Brandneed can uplift your brand’s reputation in no time.

          </p>
          <div className="edneed-btn-wrap">
            <a href="https://webneed.io/" target="_blank" rel="noreferrer" className="button btn-oval btn-o-primary">
              VISIT NOW
            </a>
          </div>
        </div>
        <div className="invoation-rightSidebar">
          <img src={WebneedImage} alt="" />
        </div>
      </div>
      </div>
    <div className="container edneed-container">
      <div className="our-feature-wrap">
        <p className="feature-p">OUR FEATURES</p>
        <div className="feature-content-wrap">
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage1} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Dynamic <br/>Website builder
              </h4>
              <p className="feature-content-p">
              Represent your business or service online by creating its presence on the internet through our website builder tool. Expand your business, attract new customers, provide information regarding the services that you offer, and create a sense of credibility in the minds of customers by showing testimonials on the website. 
              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage2} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              Online Inventory Management 
              </h4>
              <p className="feature-content-p ">
              Webneed’s inventory management feature ensures better and more accurate inventory management. Reduce the risk of overselling and avoid stockouts and the problem of excess stock with our online inventory management. Experience a more organized warehouse and earn more profits.
              </p>
            </div>
          </div>
          <div className="feature-card-container">
            <div className="backgroud-imag">
              <img src={FeatureImage3} alt="" />
            </div>
            <div className="feature-content">
              <h4 className="feature-card-header">
              85+ Stunning Templates
              </h4>
              <p className="feature-content-p ">
              Get the biggest collection of dynamic and attractive website templates at your disposal. Choose from more than 85 Stunning website templates and make your website more user friendly and appealing to eyes. Easy to use website templates that can greatly enhance the look and create a positive impact for your website.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-container">
        <div className="partner-leftSidebar">
          <p className="brand-p">BRANDS</p>
          <h4 className="client-heading">
            Clients and <br />
            Frequent <br /> Partners
          </h4>
          <p className="client-subheading">
          Helping your organization to reach its potential, driving bold ideas and pragmatic solutions for optimum outcomes.          </p>
        </div>
        <div className="partner-rightSidebar">
          <div className="parner-images-container">
          <div className="image-div ">
              <img src={c1} alt="" />
            </div>
            <div className="image-div ">
              <img src={c2} alt="" />
            </div>
            <div className="image-div ">
              <img src={c3} alt="" />
            </div>
            <div className="image-div ">
              <img src={c4} alt="" />
            </div>
            <div className='image-div '>
              <img src={c5} alt="" />
            </div>
            <div className='image-div '>
              <img src={c6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-parnet-wrap">
        <button className="button btn-oval btn-o-primary" type="button" onClick={openContactpopup}>CONTACT US</button>
      </div>
    </div>
    {openContactPopup && (
                  <FormPopup closeContactpopup={closeContactpopup} />
                )}
    </React.Fragment>
  );
};

export default Webneed;
