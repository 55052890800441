import React, { useEffect, useState } from 'react'
import InputForm from './InputForm/InputForm';
import './form.scss';   
import ValidationFile from '../../../Classes/ValidationFile';
import axios from 'axios';

const Form = () => {
    const [name,setName] = useState('') 
    const [success,setSuccess]=useState(false)
    const [nameError,setNameError]=useState(false)
    const [email,setEmail] = useState('') 
    const [mobile,setMobile] = useState('') 
    const [mobileError,setMobileError]=useState(false)
    const [message,setMessage] = useState('') 
    const [emailError,setEmailError]=useState('')
    const handlechange = (e)=> {
        let inputName = e.target.name
        let inputValue = e.target.value
        setSuccess(false)
        switch(inputName){
            case "name":
                setName(ValidationFile.spaceNotAccept(inputValue))
                setNameError(false)
                break;
                case "email":
                    setEmail(inputValue)
                break;
                case "mobile":
                    setMobile(+inputValue)
                    setMobileError(false)
                break;
                case "message":
                    setMessage(inputValue)
                break;
                default:
                    return false
        }
    }
    const validEmail = () => {
        let isValid = true;
        if (email) {
          if (!ValidationFile.validEmail(email)) {
            isValid = false;
            setEmailError(true)
          } else {
            isValid = true;
          }
        } else {
          isValid = true;
        }
        return isValid;
      }; 
      const data = ()=>{
        return{
            name:name,
            contact:mobile,
            message:message,
            email:email
        }
      }
      const submit = (e)=>{
        e.preventDefault()
        let isValid=validEmail()
        if(!ValidationFile.isEmail(email)){
          setEmailError(true)
        }
        if(ValidationFile.isEmpty(name)){
          setNameError(true)
        }
        if(ValidationFile.isEmpty(mobile)){
          setMobileError(true)
        }
        if(ValidationFile.isEmail(email) && ValidationFile.isNotEmpty(name)&&ValidationFile.isNotEmpty(mobile)){
          console.log(data())
            axios.post('https://commonservices.edneed.com/bneedCommunication/contact-request',data() )
              .then(function (response) {
                setSuccess(true)
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      }
      useEffect(()=>{
        if(success){
          setName("")
          setEmail("")
          setMessage("")
          setMobile("")
        }
      },[success])
  return (
    <div className='form-container'>
        <form onSubmit={submit}>
            <InputForm type={'text'} name="name" value={name} onChange={(e)=> handlechange(e)} onKeyUp={(e)=> handlechange(e)} placeholder={'Name'}  />
            {nameError&& <p className="input_error">Name required.</p>}
            <InputForm type={'tel'}  name="mobile" value={mobile} onChange={(e)=> handlechange(e)} onKeyUp={(e)=> handlechange(e)} placeholder={'Phone Number'}  />
            {mobileError&& <p className="input_error">Contact required.</p>}
            <InputForm type={'email'}  name="email" value={email}  onChange={(e)=> handlechange(e)} onKeyUp={(e)=> handlechange(e)} placeholder={'Email id'}  />
            {emailError && <p className="input_error">Email required.</p>}
            <InputForm type={'textarea'} maxLength="500"  value={message} name="message" onKeyUp={(e)=> handlechange(e)} onChange={(e)=> handlechange(e)} placeholder={'Message'}  />
            <div className='btn-warp'>
            {
              success?
              <button type="button" className='button btn-oval btn-o-primary'>SUBMITTTED</button>
            : <button type="submit" className='button btn-oval btn-o-primary'>SUBMIT</button>
             }
                
            </div>
        </form>
    </div>
  )
}

export default Form