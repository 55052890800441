import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ValidationFile from '../../Classes/ValidationFile';
import InputFile from '../CommonJsx/Form/InputFile/InputFile';
import InputForm from '../CommonJsx/Form/InputForm/InputForm';
import './ApplyPopup.scss'

const ApplyPopup = ({handleClosePopup,title}) => {
    const [name,setName] = useState('');
    const [jobApplyed,setJobApplyed] = useState(false);
    const [success,setSuccess]=useState(false)
    const [nameError,setNameError]=useState(false)
    const [email,setEmail] = useState('') 
    const [mobile,setMobile] = useState('') 
    const [mobileError,setMobileError]=useState(false)
    const [message,setMessage] = useState('') 
    const [emailError,setEmailError]=useState('')
    const handlechange = (e)=> {
        let inputName = e.target.name
        let inputValue = e.target.value
        setSuccess(false)
        switch(inputName){
            case "name":
                setName(inputValue)
                setNameError(false)
                break;
                case "email":
                    setEmail(inputValue)
                    setEmailError(false)
                break;
                case "mobile":
                    setMobile(+inputValue)
                    setMobileError(false)
                break;
                case "message":
                    setMessage(inputValue)
                break;
                default:
                    return false
        }
    }
    
      const data = ()=>{
        return{
            name:name,
            contact:mobile,
            message:message,
            email:email,
            designation: title,
        }
      }
      const submit = (e)=>{
        e.preventDefault()
        if(!ValidationFile.isEmail(email)){
          setEmailError(true)
        }
        if(ValidationFile.isEmpty(name)){
          setNameError(true)
        }
        if(ValidationFile.isEmpty(mobile)){
          setMobileError(true)
        }
        if(ValidationFile.isEmail(email) && ValidationFile.isNotEmpty(name)&&ValidationFile.isNotEmpty(mobile)){

            axios.post('https://commonservices.edneed.com/bneedCommunication/jobApplicantion-request',data() )
              .then(function (response) {
                setSuccess(true)
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      
      }
      useEffect(()=>{
        if(success){
          setName("")
          setEmail("")
          setMessage("")
          setMobile("")
          setTimeout(() => {
            handleClosePopup()
          }, 500);
        }
      },[success])
  return (
    <div className='apply-container'>
        <div className='apply-wrapper'>
      <div className='title-container'>
        <div className='title-wrapper'>
            {
                jobApplyed ? '' :
               <h3 className='apply-heading'>Apply to this role</h3>
              }
        </div>
        <div className='cross-wrap'>
            <i className='icon-cross' onClick={handleClosePopup}></i>
        </div>
      </div>
      {
        !jobApplyed ? (<div className='apply-body'>
        <h5 className='apply-role-heading'>Role :- {title}</h5>
        <p className='apply-role-para'>We’re looking for an experienced {title} to join our team.</p>
        <div className='apply-form'>
          <form  onSubmit={submit}>
              <InputForm type="text" name="name" value={name} onChange={(e)=> handlechange(e)} onKeyUp={(e)=> handlechange(e)}    label={"Name"}/>
              {nameError&& <p className="input_error">Name required.</p>}
            <InputForm type="tel" name="mobile"  value={mobile}  onChange={(e)=> handlechange(e)}  onKeyUp={(e)=> handlechange(e)}  label={"Phone Number"}  />
            {mobileError&& <p className="input_error">Mobile required.</p>}
            <InputForm type="email" name="email" value={email}   onChange={(e)=> handlechange(e)}   onKeyUp={(e)=> handlechange(e)}  label={"Email id"}/>
            {emailError&& <p className="input_error">Email invalid.</p>}
            <InputForm type="textArea"name="message"   value={message}   onChange={(e)=> handlechange(e)}  onKeyUp={(e)=> handlechange(e)} label={"Add a cover letter here"}/>
            {/* <InputFile /> */}
          
          <div className='form-btn-wrap'>
          {
              success?
              <button className='button btn-oval btn-primary' type='button'>SUBMITTED</button>
            : <button className='button btn-oval btn-primary' type='submit'>SUBMIT</button>
             }
           
          </div>
          </form>
        </div>
      </div>) :(
        <div className='jobApply-container'>
             <p className='cong-para'>Congratulations!</p>
             <p className='cong-position'>You have successfully applied to the SEO Marketing Manager position.</p>
             <p className='cong-keep'>Keep yourself updated as the application  is submitted. We will get back to you on mail </p>
        </div>
      )

}
      </div>
    </div>
  )
}

export default ApplyPopup